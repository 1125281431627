@use '@angular/material' as mat;

@mixin dialog-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    
    .dialog-container {
      .info-icon {
        cursor: pointer;
        color: mat.get-color-from-palette($accent);
      }

      .dialog-content-container {
        @include mat.elevation(5);
        max-width: 48.875rem;
        border-width: 0;
        padding: 1.25rem;

        .close-button {
          float: right;
        }
      }
    }
}
