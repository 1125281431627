@use '@angular/material'as mat;

@mixin jurisdiction-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $disabled-background: #e0e0e0; // Adjust as needed
  $disabled-color: #9e9e9e; // Adjust as needed

  .jurisdiction-table-container {
    padding-left: 0;
    padding-right: 0;

    .mat-card-header .mat-card-title {
      padding: 0 1rem;
    }

    .table-header-container {
      background-color: mat.get-color-from-palette($primary, 100);
      padding-left: 1rem;
      padding-right: 1rem;
      .right-content {
          margin-right: 0;
      }

      .middle-content-container {
        visibility: hidden;
        display: none;
        height: 0;
      }
    }

    .mat-table {
      min-height: 10rem;

      .mat-header-row {
        border-bottom-width: 2px;
      }

      .mat-header-cell {
        text-transform: uppercase;
        border-bottom: unset;
        text-align: left;
      }

      .mat-sort-header-content {
        text-transform: uppercase;
        text-align: left;
      }

      .mat-cell {

        span,
        div {
          white-space: unset;
          word-wrap: break-word;
        }

        .mat-table {
          min-height: unset;
          background-color: mat.get-color-from-palette($accent, 100);

          .mat-row,
          .mat-header-row,
          .mat-footer-row,
          .mat-header-cell,
          .mat-cell,
          .mat-footer-cell {
            border-bottom-width: 0;
          }
        }
      }

      
      .mat-column-drivenDistance,
      .mat-column-fuelUsed {
        justify-content: right;
        span {
          margin-right: 1.125rem;
        }
        .mat-sort-header-content {
          text-align: right;
        }
      }

      .mat-error {
        background-color: transparent;

        .error-card {
          margin: 0;
          @include mat.elevation(0);
        }
      }

      .mat-paginator-container {
        padding: 0 1.5rem;
      }

      .spinner-container {
        display: flex;
        justify-content: center;
        transform: translateY(10vh);

        .mat-progress-spinner {
          position: absolute;
        }
      }
    }
  }
}