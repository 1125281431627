@use "@angular/material" as mat;
@import "@zonar-ui/material/zonar-ui-material.theme";
@import "@zonar-ui/core/zonar-ui-core.theme";
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import "@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme";
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/table/zonar-ui-table.theme';
@import '@zonar-ui/filter/zonar-ui-filter.theme';
@import '@zonar-ui/date-picker/zonar-ui-date-picker.theme';
@import '@zonar-ui/asset-properties/zonar-ui-asset-properties.theme';

@import '../app/containers/overview-container/overview-container.theme';

@mixin apply-zonar-theme($theme){
  $primary: map-get($theme, primary);
  
  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-table-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include zonar-ui-date-picker-theme($theme);
  @include zonar-ui-asset-properties-theme($theme);

  @include overview-container-theme($theme);

  .mat-sidenav-content,
  .content-container {
    background-color: mat.get-color-from-palette($primary, 200);
  }

}

.zonar-default-theme{
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme{
  @include  apply-zonar-theme($zonar-default-theme);
  background-color: black;
}

.content-container {
  padding: 2rem;
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }

  .mat-select-panel {
    max-height: fit-content;
  }
}
